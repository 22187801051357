import Link from 'gatsby-link';
import styled from 'styled-components';
import BoxContainer from 'src/components/boxContainer/BoxContainer';
import PageBoxWrapper from 'src/components/pageBoxWrapper/PageBoxWrapper';
import { mediaQuery } from 'src/config/deviceMediaQueries';

export const Root = styled(BoxContainer)`
  background: #e1edff;
  margin-bottom: 90px;

  .light & {
    background: #304c8c;
  }
`;

export const Column = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  background: url('/img/main-page-introduction-box-background.svg');
  background-size: contain;
  border-radius: 16px;
  background-position: right bottom;
  background-repeat: no-repeat;

  @media ${mediaQuery.tablet} {
    align-items: flex-start;
    height: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 4px;
  @media ${mediaQuery.tablet} {
    flex-direction: row;
    text-align: left;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
    margin-top: 100px;
  }

  @media ${mediaQuery.navbarCollapse} {
    margin-top: -20px;
  }
`;

export const TitleWrapper = styled.div`
  font-weight: 900;
  padding: 0;
  color: #3461b6;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
    width: 48%;
  }

  .light & {
    color: #fff;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 30px;
  color: #3461b6;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
    width: 48%;
  }

  .light & {
    color: #fff;
  }
`;

export const ContentWrapper = styled(PageBoxWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled(Link)`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #4e92ff;
  color: rgb(255, 255, 255);
  margin-top: 20px;
  position: relative;

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }

  @media ${mediaQuery.tablet} {
    margin-top: 40px;
  }
`;
